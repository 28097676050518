





































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import AgGridFactory from '@/api/factories/agGridFactory'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-programs/helpers/requests'
import { GridOptions } from 'ag-grid-community'
import _ from 'lodash'

@Component({ components: { AgGridVue } })
export default class AddSpecialities extends Vue {
  @Prop(Array) readonly data!: any[]

  private rowData: any[] = []
  private selectedSpecialities: any[] = []

  public setSelected() {
    this.onDataChanged()
  }

  private nodeToText = (node: any) =>
    node ? `${node.cipher} ${node.title}` : ''

  @Watch('data')
  private onDataChanged() {
    this.selectedSpecialities = this.data

    this.gridOptions?.api?.forEachNode(node => {
      const foundNode = this.data.find(
        s => s.text === this.nodeToText(node.data)
      )
      if (foundNode) {
        node.setSelected(true)
      }
    })
  }

  private columnDefs = [
    {
      headerName: 'Номер специальности',
      field: 'cipher',
      width: 50
    },
    {
      headerName: 'Специальность',
      field: 'title',
      width: 200
    }
  ]

  private gridOptions: GridOptions = {
    rowData: [],
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      filterParams: {
        ...AgGridFactory.getDefaultFilterParams().filterParams,
        filterOptions: ['contains']
      },
      autoHeight: true,
      headerClass: 'AgGridHeader-wrap',
      cellStyle: { 'white-space': 'normal', 'line-height': 1.5 }
    },
    pagination: true,
    paginationPageSize: 30,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    enableCellChangeFlash: true,
    onCellClicked: this.onCellClicked
  }

  private onCellClicked() {
    this.selectedSpecialities = _.orderBy(
      (this.gridOptions.api?.getSelectedNodes() ?? []).map(s => ({
        id: s.data.id,
        text: this.nodeToText(s.data)
      })),
      'cipher'
    )
  }

  private onSave() {
    const postData = _.cloneDeep(this.selectedSpecialities)
    this.$emit('save', postData)
    this.selectedSpecialities = []
  }

  private async created() {
    const response = await PartnerEducationProgramsAPI.getUniqueSpecialities()
    this.rowData = response.data
  }
}
