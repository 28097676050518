




import { Component, Vue } from 'vue-property-decorator'
import PartnerEducationPrograms from '@/components/routes/partner/education-programs/index.vue'

@Component({ components: { PartnerEducationPrograms } })
export default class PartnerEducationProgramsRoute extends Vue {}
