

























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ActionRenderer from '@/components/ag-grid/renderers/ActionRenderer.vue'
import AgGridFactory from '@/api/factories/agGridFactory'
import AddSpecialities from '@/components/routes/partner/education-programs/components/AddSpecialities.vue'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-programs/helpers/requests'
import { toastMapper } from '@/store/modules/toast'

const Mappers = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({ components: { AgGridVue, ActionRenderer, AddSpecialities } })
export default class Specialities extends Mappers {
  @Prop(Number) readonly companyId!: number
  @Ref() addSpecialities!: AddSpecialities

  private rowData: any[] = []

  private columnDefs = [
    {
      headerName: 'Специальность',
      field: 'text',
      editable: false,
      width: 200
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          onDelete: this.onDelete
        },
        width: 80
      })
    }
  ]

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),

    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
      autoHeight: true,
      headerClass: 'AgGridHeader-wrap',
      filterOptions: ['contains']
    },
    cacheBlockSize: 15,
    rowBuffer: 100,
    pagination: true,
    paginationPageSize: 15,
    enableCellTextSelection: true
  }

  private onDelete(e: any) {
    this.rowData = this.rowData.filter(
      (r: any) => !(r.id === e.data.id && r.text === e.data.text)
    )
    this.onAddSpecial(this.rowData)
  }

  private async onAdd() {
    this.$bvModal.show('addPartnerSpecialitiesModal')
    await this.$nextTick()
    this.addSpecialities.setSelected()
  }

  private async onAddSpecial(spec: any[]) {
    const postData = spec.map((s: any) => ({ text: s.text }))

    await PartnerEducationProgramsAPI.updateSpecialties(
      this.companyId,
      postData
    )

    this.pushToast({
      message: 'Выполнено обновление списка специальностей',
      title: 'Выполнено'
    })

    await this.updateTable()
  }

  private async updateTable() {
    const response = await PartnerEducationProgramsAPI.getSpecialtiesPartner(
      this.companyId
    )
    this.rowData = response.data
  }

  private async created() {
    await this.updateTable()
  }
}
